import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
// import { EventBus } from '../utils/EventBus'
Vue.use(VueRouter);

const webinars = [
  {
    path: "/webinars/training-on-food-and-feed",
    name: "FoodAndFeed",
    component: () =>
      import(
        /* webpackChunkName: "FoodAndFeed" */ "../views/webinars/FoodAndFeed.vue"
      )
  },
  {
    path: "/webinars/training-on-biochemicals",
    name: "BioChemicals",
    component: () =>
      import(
        /* webpackChunkName: "BioChemicals" */ "../views/webinars/BioChemicals.vue"
      )
  },
  {
    path: "/webinars/training-on-biobased-materials",
    name: "BioBasedMaterials",
    component: () =>
      import(
        /* webpackChunkName: "BioChemicals" */ "../views/webinars/BioBasedMaterials.vue"
      )
  },
  {
    path: "/webinars/training-on-bioenergy",
    name: "BioEnergy",
    component: () =>
      import(
        /* webpackChunkName: "BioChemicals" */ "../views/webinars/BioEnergy.vue"
      )
  }
];

const internals = [
  {
    path:
      "/best-practice-examples-of-bio-based-solutions-with-replicability-potential",
    name: "RegionalPolicies",
    component: () =>
      import(
        /* webpackChunkName: "bestpractise" */ "../views/internals/BestPracticeExamples.vue"
      )
  },
  {
    path: "/repository-of-regional-policies-to-support-bio-based-models",
    name: "RegionalPolicies",
    component: () =>
      import(
        /* webpackChunkName: "regionalPolicies" */ "../views/internals/RegionalPolicies.vue"
      )
  },
  {
    path: "/repository-of-financing-instruments-and-tools",
    name: "RepositoryOfFinancingTools",
    component: () =>
      import(
        /* webpackChunkName: "repositoryOfFinancingTools" */ "../views/internals/RepositoryOfFinancingTools.vue"
      )
  },
  {
    path: "/training-in-bioeconomy-sectors",
    name: "TrainingInBioeconomySectors",
    component: () =>
      import(
        /* webpackChunkName: "bioeconomySectors" */ "../views/TrainingInBioeconomySectors.vue"
      )
  },
  {
    path: "/panorama-of-resources",
    name: "ResourcesPanorama",
    component: () =>
      import(
        /* webpackChunkName: "resourcesPanorama" */ "../views/internals/ResourcesPanorama.vue"
      )
  }
];

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/new",
    name: "New",
    component: () => import(/* webpackChunkName: "new" */ "../views/New.vue")
  },
  {
    path: "/update",
    name: "Update",
    component: () =>
      import(/* webpackChunkName: "update" */ "../views/Update.vue")
  },
  ...internals,
  ...webinars,
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "404NotFound" */ "../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.query && to.query.position && to.query.position === "steps") {
      return {
        selector: "steps",
        behavior: "smooth"
      };
    }
    return { x: 0, y: 0 };
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.query.phase) {
//     console.log(to.query.phase)
//     EventBus.$emit('goToTab', Number(to.query.phase)-1)
//   }
//   return next()
// })

export default router;
