import { render, staticRenderFns } from "./index.vue?vue&type=template&id=81c952b6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=81c952b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c952b6",
  null
  
)

/* custom blocks */
import block0 from "./en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./cz.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "./hu.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "./pl.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block3 === 'function') block3(component)
import block4 from "./sk.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block4 === 'function') block4(component)
import block5 from "./uk.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fvar%2Fbsat%2Fsrc%2Fviews%2FHome%2Findex.vue"
if (typeof block5 === 'function') block5(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VImg,VRow})
