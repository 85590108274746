import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        dark: "#182B33",
        off_white: "#FCFFF5",
        viridian: "#259051",
        light_green: "#959c4c",
        inactive_grey: "#c4c4c4"
      }
    }
  }
});
