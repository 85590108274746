<template>
  <v-app light class="app">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "BSAT | Bioeconomy Strategy Acceleraor Toolkit",
    titleTemplate: "BSAT | %s"
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
@import "./styles/main.scss";

html {
  background-color: $off_white !important;
}

body {
  background-color: $off_white !important;
  font-family: $font-family !important;
  word-break: break-word !important;
  margin-bottom: 60px;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: $light_green !important;
}

section {
  padding-bottom: 40px;
}

li {
  font-family: $font-family;
  color: $dark;
}
.v-card__title {
  word-break: break-word !important;
}

.v-btn {
  font-family: $font-family !important;
}

.v-tab {
  height: 36px;
  align-items: start !important;
  justify-content: start !important;
  padding: 8px !important;
  // min-width: 120px!important;
  border-top: 3px solid $inactive_grey;
}

.v-tabs-slider-wrapper {
  top: 0 !important;
  margin: 0 !important;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
  height: 3px !important;
}

.v-slide-group__next {
  height: 36px !important;
  flex: 0 1 32px !important;
  min-width: 32px !important;
}

.v-slide-group__prev {
  height: 36px !important;
  flex: 0 1 32px !important;
  min-width: 32px !important;
}

.v-stepper__step {
  padding: 0px !important;
}
.v-stepper__header {
  .v-divider {
    margin: 0px -30px !important;
  }
}
</style>
