import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const initState = () => ({
  languages: [
    {
      title: "Czech",
      iso: "cz"
    },
    {
      title: "English",
      iso: "en"
    },
    {
      title: "Hungarian",
      iso: "hu"
    },
    {
      title: "Polish",
      iso: "pl"
    },
    {
      title: "Ukranian",
      iso: "uk"
    },
    {
      title: "Slovak",
      iso: "sk"
    }
  ]
});

export default new Vuex.Store({
  state: { ...initState() },
  mutations: {
    SET_LANGUAGES: (state, langs) => (state.languages = langs),
    INIT_STATE: state => Object.assign(state, initState())
  },
  getters: {
    getLanguages: state => state.languages
  },
  modules: {}
});
