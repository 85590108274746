<template>
  <v-row align="center" class="hero">
    <v-col cols="12" sm="6">
      <div class="bsat__heading font-weight-bold mb-3">
        Bioeconomy <span style="color: #afd292">Strategy</span> Accelerator
        Toolkit
      </div>
      <!-- <div class="bsat__paragraph hero-paragraph">
        We guide you in developing or updating the Bioeconomy Strategy in your
        region
      </div> -->
      <div class="pt-4 pt-sm-8">
        <v-btn depressed color="dark" dark @click="scrollToSummary">{{
          $t("learnMore")
        }}</v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="6" class="order-first order-sm-last">
      <div class="d-flex justify-center align-center pl-sm-5">
        <div class="hero__img">
          <v-img contain src="@/assets/icons/hero.png"></v-img>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<i18n>{
  "en": {
    "learnMore": "Learn more"
  },
  "cz": {
    "learnMore": "Zjistit více"
  },
  "hu": {
    "learnMore": "Többet tud"
  },
  "pl": {
    "learnMore": "Ucz się więcej"
  },
  "uk": {
    "learnMore": "Вчи більше"
  },
  "sk": {
    "learnMore": "Uč sa viac"
  }
}</i18n>

<script>
export default {
  methods: {
    scrollToSummary() {
      document.getElementById("summary").scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main.scss";

.hero {
  .v-btn {
    text-transform: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 152px;
    height: 30px;
  }

  &-paragraph {
    color: $dark;
    opacity: 0.5;
    font-size: 20px;
    text-align: justify;
  }
}

.heading {
  max-width: 90%;
  word-break: break-word;
}

.hero__img {
  max-width: 400px;
  height: auto;
}

@media screen and (max-width: 420px) {
  .hero__img {
    width: 100%;
    height: auto;
  }
}
</style>
