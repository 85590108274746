<template>
  <div class="bg-off_white fill-height">
    <div class="d-flex justify-space-between pa-4 pl-8">
      <div class="logo">
        <v-img src="@/assets/icons/logo.png"></v-img>
      </div>

      <Localization />
    </div>
    <div class="bsat__container">
      <section>
        <hero-section />
      </section>

      <section id="summary">
        <div class="summary">
          <div
            v-html="$t('summary.title')"
            class="bsat__title font-weight-bold mt-9 mb-2"
          >
            {{ $t("summary.title") }}
          </div>
          <div v-html="$t('summary.paragraph')" class="summary__paragraph mb-2">
            {{ $t("summary.paragraph1") }}
          </div>
        </div>
      </section>

      <section class="action">
        <div class="action__title font-weight-bold">
          {{ $t("actions.title") }}
        </div>
        <p class="action__subtitle">{{ $t("actions.subtitle") }}</p>

        <v-card
          flat
          class="mt-8 px-2 py-4 border"
          :max-width="$vuetify.breakpoint.mdAndUp ? '80%' : 'auto'"
        >
          <v-card-title class="bsat__subtitle py-0 mb-2 font-weight-bold">{{
            $t("actions.developNewCard.title")
          }}</v-card-title>
          <v-card-text class="pb-2">{{
            $t("actions.developNewCard.cardText")
          }}</v-card-text>
          <v-card-actions class="ml-2 py-0" style="min-height: 30px">
            <v-btn
              style="color: #ffffff !important"
              color="#afd292"
              rounded
              depressed
              dark
              width="190"
              height="30"
              to="/new"
              >{{ $t("actions.developNewCard.btnText") }}</v-btn
            >
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-8 px-2 py-4 border"
          :max-width="$vuetify.breakpoint.mdAndUp ? '80%' : 'auto'"
        >
          <v-card-title class="bsat__subtitle py-0 mb-2 font-weight-bold">{{
            $t("actions.updateExistingCard.title")
          }}</v-card-title>
          <v-card-text class="pb-2">{{
            $t("actions.updateExistingCard.cardText")
          }}</v-card-text>
          <v-card-actions class="ml-2 py-0" style="min-height: 30px">
            <v-btn
              style="color: #ffffff !important"
              color="#afd292"
              rounded
              depressed
              dark
              width="190"
              height="30"
              to="/update"
              >{{ $t("actions.updateExistingCard.btnText") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </section>
      <section>
        <div class="footer">
          <v-row dense align="center">
            <v-col cols="12" sm="1">
              <div :class="$vuetify.breakpoint.xsOnly ? 'text-cener' : null">
                <v-avatar tile width="80" height="48">
                  <v-img src="@/assets/icons/eu-flag.svg"></v-img>
                </v-avatar>
              </div>
            </v-col>
            <v-col cols="12" sm="11" class="pl-sm-4">
              <div class="footer-text">{{ $t("footer.text") }}</div>
            </v-col>
          </v-row>
        </div>
      </section>
    </div>
  </div>
</template>

<i18n src="./en.json"></i18n>
<i18n src="./cz.json"></i18n>
<i18n src="./hu.json"></i18n>
<i18n src="./pl.json"></i18n>
<i18n src="./sk.json"></i18n>
<i18n src="./uk.json"></i18n>

<script>
import HeroSection from "@/components/landing/HeroSection";
import Localization from "@/components/Localization";

export default {
  name: "Home",
  metaInfo: {
    title: "Home"
  },
  components: {
    HeroSection,
    Localization
  },
  mounted() {
    this.$store.commit("INIT_STATE");
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";

.border {
  border: 1px solid lighten($color: $light_green, $amount: 35%);
}

.logo {
  max-width: 180px;
  height: auto;
}
.footer {
  padding-top: 100px;
  padding-bottom: 100px;

  &-text {
    font-family: $font-family;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark;
    text-align: justify;
  }
}

.action {
  font-family: $font-family;
  color: $dark;

  &__title {
    font-size: 20px;
  }

  &__subtitle {
    font-size: 14px;
  }
  .v-btn {
    border-radius: 8px;
    text-transform: none;
    font-size: 15px;
    letter-spacing: normal;
    font-weight: 300;
    background-color: #afd292;
  }
}

.summary {
  margin: 40px 0px;

  &__paragraph {
    // max-width: 75%;
    text-align: left;
    font-family: $font-family;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.8;
    text-align: justify;
  }
}
</style>
