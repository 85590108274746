<template>
  <div class="localization">
    <v-menu
      bottom
      left
      offset-y
      :offset-x="$vuetify.breakpoint.xsOnly"
      open-on-hover
      class="elevation-1"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :small="$vuetify.breakpoint.xsOnly"
          text
          color="light_green"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-img src="@/assets/icons/flag2.png"></v-img>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in languages"
          :key="i"
          @click="changeLanguage(item.iso)"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              <img :src="language_flag(item.iso)" height="10px" />
              {{ $t(item.title) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<i18n>
{
	"en": {
		"": "Czech",
		"english": "English",
		"hungarian": "Hungarian",
		"polish": "Polish",
		"slovakian": "Slovak",
		"ukranian": "Ukranian"
	}
}
</i18n>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tempFlag: "en"
    };
  },

  computed: {
    ...mapGetters({
      languages: "getLanguages"
    })
  },

  methods: {
    changeLanguage(iso) {
      this.$root.$i18n.locale = iso;
      console.log();
      this.tempFlag = iso;
    },
    language_flag(flag) {
      return `images/flags/${this.$i18n.t(flag)}.png`;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.localization {
  .v-btn {
    font-family: $font-family !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;

    &--active {
      color: $light_green !important;
      background-color: transparent;
    }
  }
}
</style>
